import { useEffect, useState } from "react";

import { Row, Col, Button, Card, DatePicker, Form } from "antd";

import PageTitle from "../../../layout/components/content/page-title";
import themeConfig from "../../../configs/themeConfig";
import { Link } from "react-router-dom";
import BottomMenu from "../../../layout/components/bottom-menu";

// Assets
import birthday from "../../../assets/images/illustrations/birthday.svg";
import birthdayDark from "../../../assets/images/illustrations/birthday-dark.svg";
import { useSelector } from "react-redux";

import moment from "moment";
import "moment/locale/id";
import { Calendar, CloseSquare, InfoCircle, TickSquare } from "react-iconly";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import WelcomeUser from "../../../layout/components/carousel/welcome";
import SatuSehat from "../../../layout/components/carousel/satusehat";
import { get } from "../../../utils/service";
import { API_URL } from "../../../api/config";
import { RiCoinLine, RiRefreshLine } from "react-icons/ri";
import HomeMenu from "../../../layout/components/home-menu";
import { useGetProfile } from "../../../UserProfileContext";

const { RangePicker } = DatePicker;
export default function Pages() {
  const [form] = Form.useForm();

  const dataProfile = useGetProfile(); // Get the profile data from context

  const theme = useSelector((state) => state.customise.theme);

  useEffect(() => {
    document.title = "Dashboard - " + themeConfig.appname;
  }, []);

  const [dataSource, setDataSource] = useState();

  const getData = async () => {
    let params = "";
    if (rangeValue) {
      params +=
        "?start_date=" +
        moment(rangeValue[0]).format("YYYY-MM-DD") +
        "&end_date=" +
        moment(rangeValue[1]).format("YYYY-MM-DD");
    }

    const res = await get(API_URL.dashboard, params);

    if (res?.status === 200) {
      setDataSource(res?.data);
    }
  };

  // Limit 30 Days RangePicker
  const [rangeDates, setRangeDates] = useState(null);
  const [rangeValue, setRangeValue] = useState([
    moment().subtract(30, "days"),
    moment(),
  ]);

  const disabledDate = (current) => {
    if (!rangeDates) {
      return false;
    }
    const tooLate = rangeDates[0] && current.diff(rangeDates[0], "days") >= 30;
    const tooEarly = rangeDates[1] && rangeDates[1].diff(current, "days") >= 30;
    return current > moment() || !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      form.setFieldsValue({
        date: undefined,
      });
      setRangeDates([null, null]);
    } else {
      setRangeDates(null);
    }
  };

  useEffect(() => {
    getData();
    document.title = "Dashboard - " + themeConfig.appname;
  }, [rangeValue]);

  return (
    <>
      <PageTitle
        pageTitle="Dashboard"
        rightButton={
          <RangePicker
            className="hp-w-100"
            autoComplete="off"
            placement="bottomLeft"
            placeholder={["Tgl. Awal", "Tgl. Akhir"]}
            format={"DD-MM-YYYY"}
            value={rangeDates || rangeValue}
            disabledDate={disabledDate}
            onCalendarChange={(val) => setRangeDates(val)}
            onChange={(val) => setRangeValue(val)}
            onOpenChange={onOpenChange}
            renderExtraFooter={() => "Maksimal range tanggal 30 hari"}
          />
        }
      />

      <div className="hp-content-main">
        <div className="hp-mb-16 hp-show-mobile">
          <Carousel
            additionalTransfrom={1}
            removeArrowOnDeviceType={["mobile"]}
            arrows
            centerMode={false}
            className="hp-w-100 hp-border-radius hp-border-color-black-40"
            containerClass="container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass="hp-mr-8"
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 2,
                partialVisibilityGutter: 0,
              },
              mobile: {
                breakpoint: {
                  max: 667,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 0,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 667,
                },
                items: 1,
                partialVisibilityGutter: 0,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            <div className="hp-dashboard-carousel hp-bg-color-black-0 hp-bg-color-dark-100 hp-mr-4">
              <WelcomeUser
                logo={dataProfile?.klinik?.logo}
                name={dataProfile?.klinik?.name}
              />
            </div>
            <div className="hp-dashboard-carousel hp-bg-color-black-0 hp-bg-color-dark-100 hp-mr-4">
              <SatuSehat />
            </div>
          </Carousel>
        </div>

        <HomeMenu />

        <Row gutter={[24, 24]} className="hp-mb-24">
          <Col md={8} lg={8} span={12}>
            <Link to="/booking/data">
              <Card className="hp-h-100">
                <Row className="hp-align-items-center">
                  <Col className="hp-statistic-icon-bg hp-mr-16 hp-mb-xs-16 hp-bg-color-primary-4 hp-bg-color-dark-primary">
                    <Calendar
                      className="hp-text-color-primary-1 hp-text-color-dark-primary-2 remix-icon"
                      size={24}
                    />
                  </Col>
                  <Col className="hp-mt-8" flex={"auto"}>
                    <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
                      Total Booking
                    </p>
                    <h5 className="hp-mb-4">
                      {(Number(dataSource?.appointment?.open) || 0) +
                        (Number(dataSource?.appointment?.inprogress) || 0) +
                        (Number(dataSource?.appointment?.completed) || 0)}
                    </h5>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col md={8} lg={8} span={12}>
            <Link to="/booking/data">
              <Card className="hp-h-100">
                <Row className="hp-align-items-center">
                  <Col className="hp-statistic-icon-bg hp-mr-16 hp-mb-xs-16 hp-bg-color-danger-4 hp-bg-color-dark-danger">
                    <InfoCircle
                      className="hp-text-color-danger-1 hp-text-color-dark-danger-2 remix-icon"
                      size={24}
                    />
                  </Col>
                  <Col className="hp-mt-8" flex={"auto"}>
                    <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
                      Belum Dirawat
                    </p>
                    <h5 className="hp-mb-4">
                      {dataSource?.appointment?.open ?? 0}
                    </h5>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col md={8} lg={8} span={12}>
            <Link to="/booking/data">
              <Card className="hp-h-100">
                <Row className="hp-align-items-center">
                  <Col className="hp-statistic-icon-bg hp-mr-16 hp-mb-xs-16 hp-bg-color-orange-4 hp-bg-color-dark-orange">
                    <RiRefreshLine
                      className="hp-text-color-orange-1 hp-text-color-dark-orange-2 remix-icon"
                      size={24}
                    />
                  </Col>
                  <Col className="hp-mt-8" flex={"auto"}>
                    <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
                      Sedang Dirawat
                    </p>
                    <h5 className="hp-mb-4">
                      {dataSource?.appointment?.inprogress ?? 0}
                    </h5>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col md={8} lg={8} span={12}>
            <Link to="/booking/data">
              <Card className="hp-h-100">
                <Row className="hp-align-items-center">
                  <Col className="hp-statistic-icon-bg hp-mr-16 hp-mb-xs-16 hp-bg-color-success-4 hp-bg-color-dark-success">
                    <TickSquare
                      className="hp-text-color-success-1 hp-text-color-dark-success-2 remix-icon"
                      size={24}
                    />
                  </Col>
                  <Col className="hp-mt-8" flex={"auto"}>
                    <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
                      Selesai Dirawat
                    </p>
                    <h5 className="hp-mb-4">
                      {dataSource?.appointment?.completed ?? 0}
                    </h5>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col md={8} lg={8} span={12}>
            <Link to="/bill/data">
              <Card className="hp-h-100">
                <Row className="hp-align-items-center">
                  <Col className="hp-statistic-icon-bg hp-mr-16 hp-mb-xs-16 hp-bg-color-info-4 hp-bg-color-dark-info">
                    <RiCoinLine
                      className="hp-text-color-info-1 hp-text-color-dark-info-2 remix-icon"
                      size={24}
                    />
                  </Col>
                  <Col className="hp-mt-8" flex={"auto"}>
                    <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
                      Tagihan Pasien
                    </p>
                    <h5 className="hp-mb-4">
                      {dataSource?.appointment?.cashier ?? 0}
                    </h5>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col md={8} lg={8} span={12}>
            <Link to="/booking/data">
              <Card className="hp-h-100">
                <Row className="hp-align-items-center">
                  <Col className="hp-statistic-icon-bg hp-mr-16 hp-mb-xs-16 hp-bg-color-grey-4 hp-bg-color-dark-grey">
                    <CloseSquare
                      className="hp-text-color-grey-1 hp-text-color-dark-grey-2 remix-icon"
                      size={24}
                    />
                  </Col>
                  <Col className="hp-mt-8" flex={"auto"} span={24}>
                    <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
                      Dibatalkan
                    </p>
                    <h5 className="hp-mb-4">
                      {dataSource?.appointment?.canceled ?? 0}
                    </h5>
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        </Row>

        <Row gutter={[12, 12]} className="hp-mb-24">
          <Col span={24}>
            <div className="hp-mb-16 hp-show-desktop">
              <Carousel
                additionalTransfrom={1}
                removeArrowOnDeviceType={["mobile"]}
                arrows
                centerMode={false}
                className="hp-w-100 hp-border-radius hp-border-color-black-40"
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite={false}
                itemClass="hp-mr-8"
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 2,
                    partialVisibilityGutter: 0,
                  },
                  mobile: {
                    breakpoint: {
                      max: 667,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 0,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 667,
                    },
                    items: 1,
                    partialVisibilityGutter: 0,
                  },
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                <div className="hp-dashboard-carousel hp-bg-color-black-0 hp-bg-color-dark-100 hp-mr-4">
                  <WelcomeUser
                    logo={dataProfile?.klinik?.logo}
                    name={dataProfile?.klinik?.name}
                  />
                </div>
                <div className="hp-dashboard-carousel hp-bg-color-black-0 hp-bg-color-dark-100 hp-mr-4">
                  <SatuSehat />
                </div>
              </Carousel>
            </div>
          </Col>
          <Col lg={24} span={24}>
            <Card className=" hp-border-color-black-40 hp-px-12 hp-py-16 hp-upgradePlanCardOne hp-border-none">
              <Row align="center">
                <Col span={24}>
                  <h3 className="hp-mb-8">
                    {moment().locale("id").format("DD MMMM YYYY")}
                  </h3>
                  <p className="hp-p1-body hp-mb-16 ">
                    {dataSource?.birthday?.total > 0
                      ? dataSource?.birthday?.total +
                        " pasienmu berulang tahun hari ini 🎂"
                      : "Tidak ada pasien yang berulang tahun hari ini"}
                  </p>

                  <Button className="hp-border-color-primary-1 hp-text-color-primary-1">
                    Lihat Kalender
                  </Button>
                </Col>

                <img
                  src={theme == "light" ? birthday : birthdayDark}
                  className="hp-position-absolute-top-right hp-h-100 hp-rtl-scale-x-n1 hp-d-sm-none"
                  alt="Birthday"
                />
              </Row>
            </Card>
          </Col>
        </Row>
      </div>

      <BottomMenu active="home" />
    </>
  );
}
