import { Link } from "react-router-dom";

import { Dropdown, Col, Avatar, Divider, Row, Modal, Button } from "antd";
import { Call, Logout, User } from "react-iconly";

import avatarImg from "../../../assets/images/default-profile.svg";
import logout from "../../../assets/images/illustrations/logout.svg";
import logoutDark from "../../../assets/images/illustrations/logout-dark.svg";

import themeConfig from "../../../configs/themeConfig";
import { autoLogout } from "../../../utils/logout";
import ModalDrawer from "../modal-drawer";
import { useState } from "react";
import { useSelector } from "react-redux";

const { confirm } = Modal;

export default function HeaderUser() {
  const theme = useSelector((state) => state.customise.theme);

  const [modalLogout, setModalLogout] = useState(false);

  const menu = (
    <div
      className="hp-border-radius hp-border-1 hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-p-24 hp-mt-12"
      style={{ width: 260 }}
    >
      <Row>
        <Col span={24}>
          <Link
            to="/profile"
            className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius hp-mb-8"
            style={{ marginLeft: -10, marginRight: -10 }}
          >
            <User set="curved" size={16} />

            <span className="hp-ml-8">Profil Saya</span>
          </Link>
        </Col>

        <Col span={24}>
          <a
            href={"https://wa.me/" + themeConfig.whatsapp}
            className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius "
            style={{ marginTop: -7, marginLeft: -10, marginRight: -10 }}
          >
            <Call set="curved" size={16} />

            <span className="hp-ml-8">Hubungi Admin</span>
          </a>
        </Col>
      </Row>

      <Divider className="hp-mb-16 hp-mt-6" />

      <Row>
        <Col span={24}>
          <a
            onClick={() => setModalLogout(true)}
            className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-text-color-danger-1 hp-hover-bg-danger-4 hp-hover-bg-dark-80 hp-border-radius"
            style={{ marginLeft: -10, marginRight: -10 }}
          >
            <Logout set="curved" size={16} />

            <span className="hp-ml-8 ">Logout</span>
          </a>
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Avatar src={avatarImg} size={40} className="hp-cursor-pointer" />
      </Dropdown>
      <ModalDrawer
        title={
          <>
            <div className="hp-text-center hp-mb-16">
              <img
                src={theme == "light" ? logout : logoutDark}
                alt="Welcome"
                width={"200px"}
              />
            </div>
            <h4 className="hp-mb-0 hp-text-center">
              Apakah kamu yakin akan logout dari aplikasi ini?
            </h4>
          </>
        }
        visible={modalLogout}
        closable={false}
        onClose={() => setModalLogout(false)}
        footer={false}
      >
        <Row justify="center" gutter={[16, 16]}>
          <Col span={24}>
            <Button
              className="hp-w-100 hp-border-color-danger-1 hp-text-color-danger-1"
              type="outline"
              danger
              onClick={() => autoLogout()}
            >
              Ya, Logout
            </Button>
          </Col>
          <Col span={24}>
            <Button
              type="text"
              className="hp-w-100"
              onClick={() => setModalLogout(false)}
            >
              Batal
            </Button>
          </Col>
        </Row>
      </ModalDrawer>
    </>
  );
}
